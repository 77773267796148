import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import {useLocation, useNavigate} from "react-router";
import TokenService from "../utils/TokenService";

const SET_ITEM = 'SET_ITEM'
const originalSetItem = localStorage.setItem;

localStorage.setItem = function(key, value) {
    const event = new Event(SET_ITEM);

    event.value = value;
    event.key = key;

    document.dispatchEvent(event);

    originalSetItem.apply(this, arguments);
};

const REMOVE_ITEM = 'REMOVE_ITEM'
const originalRemoveItem = localStorage.removeItem;

localStorage.removeItem = function(key) {
    const event = new Event(REMOVE_ITEM);

    event.key = key;

    document.dispatchEvent(event);

    originalRemoveItem.apply(this, arguments);
};

export const AuthContext = createContext(undefined)

export const AuthContextProvider = ( {children} ) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loginResponse, login] = useFetchHook(fetcher.login)
    const [logoutResponse, logout] = useFetchHook(fetcher.logout)
    const [isAuthenticated, setIsAuthenticated] = useState(Boolean(TokenService.getLocalAccessToken()))

    useEffect(() => {
        if (location.pathname === '/login' && isAuthenticated) {
            window.location.replace("/calculate");
        }
    }, [location])

    useEffect(function loginEffect() {
        if (loginResponse?.data?.status === 200) {
            TokenService.setLocalAccessToken(loginResponse.data.data.access)
            TokenService.setLocalRefreshToken(loginResponse.data.data.refresh)
            window.location.replace("/calculate");
        }
    }, [loginResponse])

    useEffect(function logoutEffect() {
        if (logoutResponse?.data?.status === 200) {
            TokenService.removeTokens()
        }
    }, [logoutResponse])

    const setIsAuthenticatedLocalStorage = (e) => {
        if (
            e.key === 'accessToken' &&
            (Boolean(e.value) !== isAuthenticated)
        ) {
            setIsAuthenticated(true)
        }
    }

    const setIsntAuthenticatedLocalStorage = (e) => {
        if (e.key === 'accessToken' || 'refreshToken') {
            setIsAuthenticated(false)
        }
    }

    document.addEventListener(SET_ITEM, setIsAuthenticatedLocalStorage, false);
    document.addEventListener(REMOVE_ITEM, setIsntAuthenticatedLocalStorage, false);

    const value = {
        login,
        loginResponse,
        logout,
        isAuthenticated
    }

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (context === undefined) {
        throw new Error('useAuthContext must be used within AuthContext')
    }
    return context
}