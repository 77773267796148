import React, { useEffect } from "react";
import {DeskingTable} from "./DeskingTable";
import './DeskingPage.scss'
import {useDealContext} from "../../contexts/DealContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import {Link} from "react-router-dom";
import {DeskingPageLoading} from "./DeskingPageLoading";
import {useDeskingContext} from "../../contexts/DeskingContext";

export const DeskingPage = ( { id, onSave } ) => {
    const { deskingState, fetchDeskingResponse, updateDealResponse, fetchDealResponse } = useDeskingContext()

    useEffect(function onDeskingSave(){
        if (updateDealResponse?.data?.data && !updateDealResponse.loading && !updateDealResponse.error) {
            onSave && onSave()
        }
    }, [updateDealResponse])


    if (fetchDeskingResponse?.loading) {
        return <DeskingPageLoading/>
    }

    if (!deskingState) return null;

    const carDetail = deskingState?.car_detail

    return (
        <div id="DeskingPage">
            <Box className={'DeskingPage-Box'}>
                <Typography
                    className={'car-info'}
                    fontWeight={400}
                    variant="h5"
                    gutterBottom
                >
                    Quotes for
                    <strong> {carDetail.year} {carDetail.make} {carDetail.model} </strong>
                    {buildTrimAndStyleDescriptionName(carDetail.trim, carDetail.style_description)}
                    { deskingState.is_purchase ? ' [Purchase]' : ' [Lease]'}
                    <Link to={`/deals/${deskingState.id}`} target="_blank" rel="noopener noreferrer" >
                        <OpenInNewOutlinedIcon />
                    </Link>
                </Typography>
                <DeskingTable dealState={deskingState} dealId={id}/>
            </Box>
        </div>
    )
}