import {Text, View} from "@react-pdf/renderer";
import React from "react";
import QuoteHelper from "../../../../utils/QuoteHelper";
import {baseStyles} from "../styles";

export const LenderInfoPDF = ( { deal } ) => {
    const helper = new QuoteHelper(deal)
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
            }}
        >
            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >
                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    Lender:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { helper.lender() }
                </Text>
            </View>
            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >
                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    Lease Type:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { helper.leaseType() }
                </Text>
            </View>

            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >
                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    Term:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { deal.term }
                </Text>
            </View>

        </View>
    )
}