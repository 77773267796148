import React from 'react'

import './PricingListTableHeaders.scss'

// Due to sticky headers, this must be placed within a th element.
export const PricingListTableHeaders = ( { headerHeight }) => {
    return (
        <thead
            style={{ height: headerHeight }}
            id={'PricingListTableHeaders'}
        >
            <tr>
                <th
                    className={'year-header'}
                >
                    <div
                        className={'header-div'}
                    >
                        Year
                    </div>
                </th>
                <th
                    className={'model-header'}
                >
                    <div
                        className={'header-div'}
                    >
                        Model
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Trim
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Description
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        MSRP
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Invoice
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Above
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Back
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Profit
                    </div>
                </th>
                <th>
                    <div
                        className={'header-div'}
                    >
                        Quotes
                    </div>
                </th>
            </tr>
        </thead>
    )
}