import {useDealContext} from "../../contexts/DealContext";
import React, {useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useMediaQuery} from "react-responsive";
import CurrencyInput from "react-currency-input-field";
import QuoteHelper from "../../utils/QuoteHelper";
import Switch from "@mui/material/Switch";

export const DealPageMonthlyTaxItem = () => {
    const {dealState, updateDealResponse, setDealStateWithUpdate} = useDealContext()
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const helper = new QuoteHelper(dealState)

    const isLoading = updateDealResponse?.loading

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': {border: 0},
                height: '10px',
            }}
        >

            <TableCell
                className={'DealPageFee fee-name'}
                scope="row"
                size={'small'}
                width={'25%'}
                align="left"
            >
                Total Monthly Tax
            </TableCell>
            {
                !isTabletOrMobile &&

                <TableCell
                    className={'DealPageFee fee-name'}
                    scope="row"
                    size={'small'}
                    width={'25%'}
                    align="right"
                >
                    <CurrencyInput
                        className={'payment-row_value disabled'}
                        prefix="$"
                        value={dealState.is_purchase ? dealState.selling_price :  helper.totalBasePayments()}
                        disabled={true}
                    />
                </TableCell>
            }
            <TableCell
                className={'DealPageFee'}
                align="right"
                size={'small'}
                width={'20%'}
            >

                <Switch
                    label="Label"
                    disabled={isLoading}
                    checked={dealState.is_taxes_financed}
                    onChange={() => setDealStateWithUpdate({is_taxes_financed: !dealState.is_taxes_financed}, true)}
                />
            </TableCell>
            <TableCell
                align="right"
                size={'small'}
                className={'DealPageFee'}
                width={'10%'}
            >
                <CurrencyInput
                    className={'payment-row_value disabled'}
                    prefix="$"
                    value={dealState.is_purchase ? helper.purchaseMonthlyTax() :  helper.totalMonthlyUseTax()}
                    disabled={true}
                />
            </TableCell>
        </TableRow>
    )
}