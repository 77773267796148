import React from "react";
import './App.scss';
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import {routes} from "./routes";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return <RouterProvider router={createBrowserRouter(routes)}/>
}

export default App;
