import { useState } from 'react';
import {useDebounce} from "@uidotdev/usehooks";
import useDidMountEffect from "./useDidMountEffect";
import {useDealContext} from "../contexts/DealContext";

const useFeeStateHook = (fee) => {
    const { dealState, setDealStateWithUpdate } = useDealContext()
    const [feeState, setFeeState] = useState(fee)

    const debouncedFeeState = useDebounce(feeState, 100)

    const setFeeStateWithUpdate = (newState, update = true) => {
        return setFeeState({...feeState, ...newState, _update: update})
    }

    useDidMountEffect(function updateFeeFromBackend(){
        setFeeState({...fee, _update: false})
    }, [])

    useDidMountEffect(function updateDealState(){
        if (feeState) {
            let newDealState = structuredClone(dealState)
            let allFees = newDealState.selected_quote['Quote']['Fees']

            allFees.forEach((el, index, array) => {
                if (
                    (feeState.TitleId === el.TitleId) &&
                    (feeState.Name === el.Name)
                ) {
                    array[index] = feeState
                }
            })

            newDealState.selected_quote['Quote']['Fees'] = allFees
            setDealStateWithUpdate(newDealState, feeState?._update)
        }
    }, [debouncedFeeState])


    return [feeState, setFeeStateWithUpdate]
}

export default useFeeStateHook;