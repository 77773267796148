import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const TradeInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Trade Information</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text style={baseStyles.flex}>
                        Gross Trade (Allowance)
                    </Text>
                    <Text>{helper.grossTradeAmount()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>
                        Owed Trade (Payoff)
                    </Text>
                    <Text>{helper.owedTradeAmount()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>
                        Trade Equity (Applied)
                    </Text>
                    <Text>{helper.tradeEquityAmount()}</Text>
                </View>
            </View>
        </View>
    )
}