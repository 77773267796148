import {useEffect} from "react";

export const useFormResponse = (setError, formResponse, deps) => {
    useEffect(() => {
      if (formResponse?.error && !formResponse?.loading) {
          const errorObject = formResponse?.error.response.data
          for (const [key, value] of Object.entries(errorObject)) {
              setError(key, { type: 'custom', message: value });
          }
      } else {

      }
    }, deps);
}