import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerFinanceDetailsPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Finance Details</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Sales Subtotal</Text>
                    <Text>{helper.salesSubtotal()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Sales Tax ({helper.taxRate()})</Text>
                    <Text>{helper.taxAmount()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Fees</Text>
                    <Text>{helper.totalFeeAmount()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Balance Due</Text>
                    <Text>{helper.balanceDue()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Finance Charge</Text>
                    <Text>{helper.financeCharge()}</Text>
                </View>


                <View style={{...baseStyles.boldTopBorder, ...baseStyles.flexBetween}}>
                    <Text>Total of Payments</Text>
                    <Text>{helper.totalOfPayments()}</Text>
                </View>
            </View>
        </View>
    )
}