import React, {useEffect} from "react";
import DeskingHelper from "../../utils/DeskingHelper";

export const DeskingTableHeaders = ( { headers } ) => {
    return (
        <thead>
            <tr>
                <th className={'term'}>Term</th>
                {
                    headers.map( (header) => <th key={header}> { header } </th>)
                }
            </tr>
        </thead>
    )
}