import React from 'react';

import QuoteHelper from "../../utils/QuoteHelper";
import DeskingHelper from "../../utils/DeskingHelper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import classnames from "classnames";
import {useDealContext} from "../../contexts/DealContext";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import Tooltip from "@mui/material/Tooltip";
import {useModalContext} from "../../contexts/ModalContext";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {CustomSelect} from "../../components/CustomSelect";


const mileageOptions = [5000, 7500, 10000, 10500, 12000, 15000]

const termOptions = ['18','24','27','30','33','36','39','42','45','48','60','72']

export const DealPagePayment = ({ isDealPage }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const {
        dealState,
        dealColorLight,
        setDealStateWithUpdate,
        createDealResponse,
        updateDealResponse,
        createUpdateFunc
    } = useDealContext()
    const { setIsDeskingModalOpen } = useModalContext()
    const quoteHelper = new QuoteHelper(dealState)

    if (!dealState) return null;

    const isLoading = updateDealResponse?.loading
    const isPurchase = dealState.is_purchase
    const terms = termOptions
    const isDisabled = !dealState.msrp || !dealState.cost

    return (
        <Stack
            direction={isTabletOrMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            className={'dealPagePayment'}
        >
            <Box className={classnames("dealPagePayment-column leftColumn", {
                mobile: isTabletOrMobile
            })}>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header'}
                >
                    Vehicle Pricing
                </Typography>
                <Divider className={'divider'}/>

                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        MSRP:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={dealState.msrp}
                        onChange={(value) => setDealStateWithUpdate({msrp: value})}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Cost:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={dealState.cost}
                        onChange={(value) => setDealStateWithUpdate({cost: value})}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Profit:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        prefix="$"
                        value={dealState.profit}
                        onChange={(value) => setDealStateWithUpdate({profit: value})}
                    />
                </div>
                <Divider className={'divider'} />
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Selling Price:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value disabled', {
                            loading: isLoading
                        })}
                        value={dealState.selling_price || (Number(dealState.cost) + Number(dealState.profit))}
                        disabled={true}
                    />
                </div>
                <Divider className={'divider'} />
                {
                    isDealPage &&
                    <React.Fragment>
                        <div
                            className={'payment-row'}
                        >
                            <span
                                className={'payment-row_name'}
                            >
                                { isPurchase ? 'Custom Down Payment' : 'Custom Drive Off:'}
                            </span>
                            <CustomCurrencyInput
                                className={classnames('payment-row_value', {
                                    loading: isLoading
                                })}
                                allowNull={true}
                                prefix="$"
                                value={dealState.drive_off}
                                onChange={(value) => setDealStateWithUpdate({drive_off: value})}
                            />
                        </div>
                        <Divider className={'divider'} />
                    </React.Fragment>
                }
                {
                    dealState.id &&
                    <React.Fragment>
                        <div
                            className={'payment-row'}
                        >
                        <span
                            className={'payment-row_name'}
                        >
                            Term:
                        </span>
                            <CustomSelect
                                className={classnames('payment-row_value select', {
                                    loading: isLoading
                                })}
                                value={dealState.term}
                                onChange={(event, newValue) => setDealStateWithUpdate({term: newValue})}
                                handleHomeEndKeys
                                options={terms}
                                getOptionLabel={(option) => String(option)}
                                freeSolo
                                forcePopupIcon
                            />
                        </div>
                        <div
                            className={'payment-row'}
                        >
                            {
                                !isPurchase &&
                                <>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Mileage:
                                    </span>
                                    <CustomSelect
                                        onChange={(event, newValue) => setDealStateWithUpdate({mileage: newValue})}
                                        className={classnames('payment-row_value select', {
                                            loading: isLoading
                                        })}
                                        options={mileageOptions}
                                        value={dealState.mileage}
                                        getOptionLabel={(option) => String(option)}
                                    />
                                </>
                            }
                        </div>
                    </React.Fragment>
                }
                <Tooltip title="Click or Press Enter to Calculate" arrow placement={'bottom'}>
                    <Button
                        className={classnames('dealPage-button', {
                            isDisabled: isDisabled
                        })}
                        style={{ background: dealColorLight() }}
                        onClick={createUpdateFunc}
                        disabled={isDisabled}
                    >
                        {
                            isLoading ? 'Calculating...' : 'Calculate'
                        }
                        {
                            isLoading ? null : <KeyboardReturnIcon/>
                        }
                    </Button>
                </Tooltip>
            </Box>

            <Box className={classnames("dealPagePayment-column rightColumn", {
                mobile: isTabletOrMobile
            })}>
                {
                    dealState.id &&
                    <React.Fragment>
                        <Typography
                            variant="h6"
                            component="div"
                            className={'header'}
                        >
                            <div className={'lender-details_container'}>
                                <span>Lender Details</span>
                                <span
                                    style={{ background: dealColorLight() }}
                                    className={'action-icon'}
                                >
                                    <Tooltip title={'Quotes'} arrow placement={'top'}>
                                        <RequestQuoteOutlinedIcon
                                            id={'someId'}
                                            className={'action-icon'}
                                            onClick={() => setIsDeskingModalOpen(
                                                {
                                                    id: dealState.id,
                                                    onSave: (updateData) => setDealStateWithUpdate(updateData, false),
                                                }
                                              )
                                            }
                                        />
                                    </Tooltip>
                                </span>
                            </div>
                        </Typography>
                        <Divider className={'divider'}/>
                        <div className={'lender-name'}>
                            {quoteHelper.lender()}
                        </div>
                        {
                            !isPurchase &&
                            <>
                                <div className={'payment-row'}>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Money Factor:
                                    </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value disabled', {
                                            loading: isLoading
                                        })}
                                        prefix=""
                                        value={quoteHelper.moneyFactor()}
                                        disabled={true}
                                        thousandSeparator={false}
                                        InputProps={{}}
                                    />
                                </div>
                                <div className={'payment-row'}>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Money Factor Override:
                                    </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value', {
                                            loading: isLoading
                                        })}
                                        prefix=""
                                        value={dealState.money_factor_override}
                                        allowBlank={true}
                                        thousandSeparator={false}
                                        InputProps={{}}
                                        onChange={(value) =>  setDealStateWithUpdate({money_factor_override: value})}
                                    />
                                </div>
                                <div className={'payment-row'}>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Residual:
                                    </span>
                                    <span>
                                        <CustomCurrencyInput
                                            className={classnames('payment-row_value disabled', {
                                                loading: isLoading
                                            })}
                                            value={quoteHelper.residual()}
                                            disabled={true}
                                            thousandSeparator={false}
                                            InputProps={
                                                {
                                                    endAdornment: (
                                                        <InputAdornment
                                                            sx={{ fontSize: '10px' }}
                                                            position="start">%
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                    </span>
                                </div>
                                <div className={'payment-row'}>
                                <span
                                    className={'payment-row_name'}
                                >
                                    Residual Override:
                                </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value', {
                                            loading: isLoading
                                        })}
                                        thousandSeparator={false}
                                        InputProps={
                                            {
                                                endAdornment: (
                                                    <InputAdornment
                                                        sx={{ fontSize: '10px' }}
                                                        position="start">%
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        prefix=""
                                        value={dealState.residual_override}
                                        allowBlank={true}
                                        onChange={(value) =>  setDealStateWithUpdate({residual_override: value})}
                                    />
                                </div>
                                <div className={'payment-row'}>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Amount Due at Signing:
                                    </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value disabled', {
                                            loading: isLoading
                                        })}
                                        prefix="$"
                                        value={quoteHelper.amountDueOnDelivery()}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        }
                        {
                            isPurchase &&
                            <React.Fragment>
                                <div className={'payment-row'}>
                                        <span
                                            className={'payment-row_name'}
                                        >
                                            APR:
                                        </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value disabled', {
                                            loading: isLoading
                                        })}
                                        InputProps={
                                            {
                                                endAdornment: (
                                                    <InputAdornment
                                                        sx={{ fontSize: '10px' }}
                                                        position="start">%
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        value={`${quoteHelper.apr()}`}
                                        disabled={true}
                                    />
                                </div>
                                <div className={'payment-row'}>
                                <span
                                    className={'payment-row_name'}
                                >
                                    APR Override:
                                </span>
                                    <CustomCurrencyInput
                                        className={classnames('payment-row_value', {
                                            loading: isLoading
                                        })}
                                        thousandSeparator={false}
                                        InputProps={
                                            {
                                                endAdornment: (
                                                    <InputAdornment
                                                        sx={{ fontSize: '10px' }}
                                                        position="start">%
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        prefix=""
                                        value={dealState.interest_rate_override}
                                        allowBlank={true}
                                        onChange={(value) =>  setDealStateWithUpdate({interest_rate_override: value})}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        <div className={'payment-row'}>
                                    <span
                                        className={'payment-row_name'}
                                    >
                                        Base Payment:
                                    </span>
                            <CustomCurrencyInput
                                className={classnames('payment-row_value disabled', {
                                    loading: isLoading
                                })}
                                prefix="$"
                                value={isPurchase ? quoteHelper.payment() : quoteHelper.basePayment()}
                                disabled={true}
                            />
                        </div>
                        <div className={'payment-row'}>
                                <span
                                    className={'payment-row_name'}
                                >
                                    Tax Rate:
                                </span>
                            <CustomCurrencyInput
                                className={classnames('payment-row_value disabled', {
                                    loading: isLoading
                                })}
                                prefix="$"
                                value={quoteHelper.taxRate()}
                                disabled={true}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment
                                                sx={{ fontSize: '10px' }}
                                                position="start">%
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                        </div>
                        <div className={'payment-row'}>
                        <span
                            className={'payment-row_name'}
                        >
                            Monthly Payment:
                        </span>
                            <CustomCurrencyInput
                                className={classnames('payment-row_value disabled', {
                                    loading: isLoading
                                })}
                                prefix="$"
                                value={isPurchase ? quoteHelper.monthlyPayment() : quoteHelper.regularPayment()}
                                disabled={true}
                            />
                        </div>
                    </React.Fragment>
                }
            </Box>
        </Stack>
    )
}