import React from 'react'
import {Document, Page, Text, View} from "@react-pdf/renderer";

import { baseStyles} from "../../styles";
import {FeeBreakdownPDF} from "../../components/FeeBreakdownPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {LenderInfoPDF} from "../../components/LenderInfoPDF";
import {LocationInfoPDF} from "../../components/LocationInfoPDF";
import {PurchaseDealerPaymentInformationPDF} from "./PurchaseDealerPaymentInformationPDF";
import {PurchaseDealerProfitInformationPDF} from "./PurchaseDealerProfitInformationPDF";
import {PurchaseDealerPriceBreakdownPDF} from "./PurchaseDealerPriceBreakdownPDF";
import {PurchaseDealerDownPaymentBreakdownPDF} from "./PurchaseDealerDownPaymentBreakdownPDF";
import {PurchaseDealerFinanceDetailsPDF} from "./PurchaseDealerFinanceDetailsPDF";
import {PurchaseDealerTaxInformationPDF} from "./PurchaseDealerTaxInformationPDF";
import {PurchaseDealerRateInformationPDF} from "./PurchaseDealerRateInformationPDF";
import {RebatesBreakdownPDF} from "../../components/RebatesBreakdownPDF";
import {HeaderPDF} from "../../components/HeaderPDF";

export const PurchaseDealerDisclosurePDF = ( { deal, companyName } ) => {
    if (!deal || ! deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>
                <HeaderPDF deal={deal} pdfName={'Dealer Disclosure'} companyName={companyName}/>

                <VehicleInfoPDF deal={deal}/>

                <View style={{...baseStyles.tablesLayout}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <PurchaseDealerPaymentInformationPDF deal={deal}/>
                        <PurchaseDealerProfitInformationPDF deal={deal}/>
                        <PurchaseDealerRateInformationPDF deal={deal}/>
                        <FeeBreakdownPDF deal={deal}/>
                        <RebatesBreakdownPDF deal={deal}/>
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <PurchaseDealerPriceBreakdownPDF deal={deal}/>
                        <PurchaseDealerDownPaymentBreakdownPDF deal={deal}/>
                        <PurchaseDealerFinanceDetailsPDF deal={deal}/>
                        <PurchaseDealerTaxInformationPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}