import React, {createContext, useContext, useState} from 'react';


export const UIContext = createContext(undefined)



const DRAWER_OPEN_WIDTH = 200
const DRAWER_CLOSED_WIDTH = 65

const initialState = {
    sideDrawerOpen: false,
    sideDrawerPinned: false,
    sideNavDrawerWidth: DRAWER_CLOSED_WIDTH
}

export const UIContextProvider = ({ children } ) => {
    const [uiState, setUIState] = useState(initialState)

    const setUIStateField = (newState) => {
        setUIState({...uiState, ...newState})
    }

    const toggleSideNavDrawer = (open) => {
        setUIState({
            ...uiState,
            sideDrawerOpen: open,
            sideNavDrawerWidth: open ? DRAWER_OPEN_WIDTH : DRAWER_CLOSED_WIDTH
        })
    }

    const togglePinSidebarDrawer = () => {
        setUIState({
            ...uiState,
            sideDrawerPinned: !uiState.sideDrawerPinned,
        })
    }

    const value = {
        uiState,
        setUIState,

        toggleSideNavDrawer,
        togglePinSidebarDrawer,

        setUIStateField
    }

    return (
        <UIContext.Provider value={value}>
            { children }
        </UIContext.Provider>
    )
}

export const useUIContext = () => {
    const context = useContext(UIContext)

    if (context === undefined) {
        throw new Error('useUIContext must be used within UIContext')
    }
    return context
}