import React, { memo } from "react";
import { NumericFormat } from 'react-number-format';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const inputAdornment = (
    <InputAdornment
        sx={{ fontSize: '10px' }}
        position="start">$
    </InputAdornment>
)

const CustomInput = memo(function CustomInput(props) {
    return (
        <TextField
            id="outlined-basic"
            variant="outlined"
            InputProps={{
                startAdornment: inputAdornment
            }}
            {...props}
        />
        )
})

const CustomCurrencyInput = ({ value, allowNull, allowBlank, onChange, className, ...props }) => {
    return (
        <NumericFormat
            value={value ?? ''}
            customInput={CustomInput}
            type="text"
            thousandSeparator
            allowLeadingZeros={false}
            onValueChange={(values) => {
                let returnVal
                const { value } = values

                if (value === '') {
                    returnVal = '0'
                    if (allowNull) {
                        returnVal =  null

                    }
                    if (allowBlank) {
                        returnVal =  ''
                    }
                } else {
                    returnVal = value
                }
                onChange && onChange(returnVal)
            }}
            {...props}
            prefix={''}
            className={`CustomInput ${className}`}
        />
    )
}

export default React.memo(CustomCurrencyInput)