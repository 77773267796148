import Modal from "@mui/material/Modal";
import React from 'react'

import Box from "@mui/material/Box";
import {LocationsTable} from "../../components/LocationsTable";
import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";


export const LocationModal = (props) => {
    const {
        initialLocation,
        selectLocation,
        open,
        onClose,
        setIsOpen,
        isSaving,
        saveButtonText
    } = props
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const style = {
        position: 'absolute',
        top: isTabletOrMobile ? '53%' : '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: 24,
        padding: '100px 120px',
        zIndex: '1400',
        outline: 'none',
        borderRadius: '6px'
    };


    return (
        <Modal
            style={{zIndex: 1400}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={style}>
                <div style={{ position: 'relative' }}>
                    {
                        isTabletOrMobile &&
                        <span
                            onClick={() => setIsOpen(null)}
                            style={{
                                position: 'absolute',
                                top: '-37px',
                                left: '0',
                                display: 'flex',
                                fontWeight: 'bold',
                                fontSize: '16px'
                            }}
                        >
                                <CloseIcon/> Close
                            </span>
                    }
                    <LocationsTable
                        initialLocation={initialLocation}
                        selectLocation={selectLocation}
                        isDealLocation={true}
                        isSaving={isSaving}
                        saveButtonText={saveButtonText}
                    />
                </div>
            </Box>
        </Modal>
    )
}