import Stack from "@mui/material/Stack";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import {usePricingContext} from "../../../contexts/PricingContext";
import {useModalContext} from "../../../contexts/ModalContext";
import CircularProgress from "@mui/material/CircularProgress";


const getInitialState = (style_description) => {
    return {
        description: '',
        msrp: 0,
        invoice: 0,
        above: 0,
        back: 0,
        profit: 0,
        car: style_description.car,
    }
}

export const CreatePricingSectionMobile = ( { style_description } ) => {
    const { createPricing } = usePricingContext()
    const [isCreating, setIsCreating] = useState(false)
    const [pricingState, setPricingState] = useState(getInitialState(style_description))


    const { setIsDealModalOpen, setIsDeskingModalOpen } = useModalContext()

    const createNewPricing = () => {
        setIsCreating(true)
        return createPricing(pricingState).then((data) => {
            setIsCreating(false)
            if (data?.status === 200) {
                setIsDeskingModalOpen({id: data?.data?.id})
            }
        }).catch(() => setIsCreating(false))
    }

    return (
        <Box
            className={'CreatePricingSectionMobile'}
        >
            <h3>Start a new quote</h3>
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="start"
            >
                <div>
                    <div>
                        <label>Description</label>
                        <input
                            className="form-control"
                            type="text"
                            value={pricingState.description}
                            onChange={(e) => setPricingState({...pricingState, description: e.target.value})}
                        />
                    </div>
                    <div>
                        <label>MSRP</label>
                        <CustomCurrencyInput
                            value={pricingState.msrp}
                            onChange={(value) => setPricingState({...pricingState, msrp: value})}
                            prefix="$"
                        />
                    </div>
                    <div>
                        <label>Invoice</label>
                        <CustomCurrencyInput
                            value={pricingState.invoice}
                            onChange={(value) => setPricingState({...pricingState, invoice: value})}
                            prefix="$"
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <label>Above</label>
                        <CustomCurrencyInput
                            value={pricingState.above}
                            onChange={(value) => setPricingState({...pricingState, above: value})}
                            prefix="$"
                        />
                    </div>
                    <div>
                        <label>Back</label>
                        <CustomCurrencyInput
                            value={pricingState.back}
                            onChange={(value) => setPricingState({...pricingState, back: value})}
                            prefix="$"
                        />
                    </div>
                    <div>
                        <label>Profit</label>
                        <CustomCurrencyInput
                            value={pricingState.profit}
                            onChange={(value) => setPricingState({...pricingState, profit: value})}
                            prefix="$"
                        />
                    </div>
                </div>
            </Stack>
            <Stack
                direction={'row'}
                alignItems="center"
                justifyContent="center"
            >
                <Button
                    onClick={createNewPricing}
                >
                    { isCreating ? <CircularProgress size={20}/>: 'Create Quote'}
                </Button>
            </Stack>
        </Box>
    )
}