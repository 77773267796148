import React from "react";
import SearchIcon from '@mui/icons-material/Search';

import {usePricingContext} from "../../contexts/PricingContext";
import _ from "lodash";


export const PricingListTableSearchBarHeader = ( { make, headerHeight } ) => {
    const { searchValue, setSearchValue } = usePricingContext()
    return (
        <div
            style={{ height: headerHeight }}
            className={'PricingListTableSearchBarHeader'}
        >
            <div className={'PricingListTableSearchBarHeader_make-name'}>
                {_.capitalize(make)} Pricing
            </div>
            <div className={'PricingListTableSearchBarHeader_search-container'}>
                <input
                    className=""
                    type="text"
                    value={searchValue}
                    placeholder={'Keyword search a car'}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <SearchIcon/>
            </div>
        </div>
    )
}