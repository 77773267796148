import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import useDidMountEffect from "../hooks/useDidMountEffect";
import {useDebounce} from "@uidotdev/usehooks";
import {useSearchParams} from "react-router-dom";


export const ListDealsContext = createContext(undefined)

const initialPaginationResponse = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    has_more: false,
    current_page: 0
}

export const ListDealsContextProvider = ({ make = '', children } ) => {
    const [listDealsState, setListDealState] = useState(initialPaginationResponse)

    const [fetchListDealsResponse, fetchListDeals] = useFetchHook(fetcher.getDeals)
    const [deleteDealResponse, deleteDeal] = useFetchHook(fetcher.deleteDeal)
    const [deletedIdsState, setDeletedIds] = useState([])

    const [searchValue, setSearchValue] = useState(make)
    const [searchParams, setSearchParams] = useSearchParams();

    const debouncedSearch = useDebounce(searchValue, 300)

    const fetchNextDeals = () => {
        let queryParams = ''
        if (listDealsState.current_page !== 0) {
            queryParams += `page=${listDealsState.current_page + 1}`
        }

        if (searchParams.get('search')) {
            setSearchValue(searchParams.get('search'))
            queryParams += `&${searchParams}`
        }

        if (searchParams.get('pricing_id')) {
            queryParams += `&pricing_id=${searchParams.get('pricing_id')}`
        }

        return fetchListDeals(queryParams)
    }

    const shouldFetchNextDeals = () => {
        const data = fetchListDealsResponse?.data?.data
        if (!data?.has_more) return false;
        if (fetchListDealsResponse?.loading) return false;

        return true
    }

    const removeDeletedDealFromList = (id) => {
        const newList = listDealsState.results.filter((deal) => deal.id !== id)
        const newDeletedIds = deletedIdsState
        newDeletedIds.push(id)
        setDeletedIds(newDeletedIds)
        setListDealState({...listDealsState, results: newList})
    }

    useEffect(function fetchListDealsFromBackendOnMount(){
        fetchNextDeals()
    }, [])

    useDidMountEffect(function updateListDealsFromBackend(){
        const response = fetchListDealsResponse?.data?.data

        if (response) {
            const currentPage = response.current_page
            if (currentPage === 1) {
                setListDealState(fetchListDealsResponse?.data?.data)
            } else {
                if (currentPage !== listDealsState.current_page) {
                    const currentList = listDealsState.results
                    const data = fetchListDealsResponse?.data?.data

                    const newList = currentList.concat(data.results).filter((d) => !deletedIdsState.includes(d.id))
                    setListDealState({...data, results: newList})

                }
            }
        }
        
    }, [fetchListDealsResponse])

    useDidMountEffect(function updateSearchParams(){
        if (searchValue.length) {
            setSearchParams({search: searchValue})
        } else {
            setSearchParams()
        }

        return () => setSearchParams()
    }, [debouncedSearch])

    useDidMountEffect(function fetchListDealsWhenSearchParamsChange(){
        if (searchParams.get('search')) {
            fetchListDeals(searchParams)
        } else {
            fetchListDeals()
        }
    }, [searchParams])

    useDidMountEffect(function updateListAfterDelete() {
        if (deleteDealResponse?.data?.id) {
            removeDeletedDealFromList(deleteDealResponse?.data?.id)
        }
    }, [deleteDealResponse])

    const value = {
        listDealsState,
        setListDealState,

        fetchListDeals,
        fetchListDealsResponse,

        deleteDeal,
        deleteDealResponse,

        searchValue,
        setSearchValue,

        fetchNextDeals,
        removeDeletedDealFromList,

        shouldFetchNextDeals
    }

    return (
        <ListDealsContext.Provider value={value}>
            { children }
        </ListDealsContext.Provider>
    )
}

export const useListDealsContext = () => {
    const context = useContext(ListDealsContext)

    if (context === undefined) {
        throw new Error('useListDealsContext must be used within ListDealsContext')
    }
    return context
}