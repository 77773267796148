class CookieService {
    getItem(key) {
        return localStorage.getItem(key)
    }

    setItem(key, value) {
        return localStorage.setItem(key, value)
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }
}

const service = new CookieService()
export default service;