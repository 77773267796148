import React from 'react'
import {Document, Page, Text, View} from "@react-pdf/renderer";

import { baseStyles} from "../../styles";
import {AmountDueAtSigningPDF} from "../../components/AmountDueAtSigningPDF";
import {PaymentInformationPDF} from "../../components/PaymentInformationPDF";
import {TaxInformationPDF} from "../../components/TaxInformationPDF";
import {FeeBreakdownPDF} from "../../components/FeeBreakdownPDF";
import {RateInformationPDF} from "../../components/RateInformationPDF";
import {CapitalizedCostBreakdownPDF} from "../../components/CapitalizedCostBreakdownPDF";
import {MileageInformationPDF} from "../../components/MileageInformationPDF";
import {ProfitInformationPDF} from "../../components/ProfitInformationPDF";
import {ResidualInformationPDF} from "../../components/ResidualInformationPDF";
import {TradeInformationPDF} from "../../components/TradeInformationPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {HeaderPDF} from "../../components/HeaderPDF";

export const DealerDisclosurePDF = ( { deal, companyName } ) => {
    if (!deal || ! deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>
                <HeaderPDF deal={deal} pdfName={'Dealer Disclosure'} companyName={companyName}/>

                <VehicleInfoPDF deal={deal}/>

                <View style={{...baseStyles.tablesLayout}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <AmountDueAtSigningPDF deal={deal} isDealerDisclosure={true}/>
                        <PaymentInformationPDF deal={deal}/>
                        <RateInformationPDF deal={deal}/>
                        <ProfitInformationPDF deal={deal}/>
                        <ResidualInformationPDF deal={deal}/>
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <CapitalizedCostBreakdownPDF deal={deal}/>
                        <TradeInformationPDF deal={deal} isDealerDisclosure={true}/>
                        <TaxInformationPDF deal={deal}/>
                        <MileageInformationPDF deal={deal} isDealerDisclosure={true}/>
                        <FeeBreakdownPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}