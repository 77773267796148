import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseCustomerPriceBreakdownPDF = ({ deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Price Breakdown</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Selling Price</Text>
                    <Text>{deal.selling_price}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Sales Tax</Text>
                    <Text>{helper.taxAmount()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Fees</Text>
                    <Text>{helper.totalFeeAmount()}</Text>
                </View>

                <View style={{...baseStyles.boldTopBorder, ...baseStyles.flexBetween}}>
                    <Text>Total Sale Price</Text>
                    <Text>{helper.totalSalePrice()}</Text>
                </View>
            </View>
        </View>
    )
}