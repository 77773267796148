import React from 'react'
import {ListDealCard} from "./ListDealCard";
import InfiniteScroll from "react-infinite-scroller";
import {useListDealsContext} from "../../contexts/ListDealsContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {ListDealMobileCard} from "./ListDealMobileCard";

export const ListDealsList = ( { listDealsState, setSelectedDealId, selectedDealId } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const {
        fetchListDealsResponse,
        fetchNextDeals,
        shouldFetchNextDeals
    } = useListDealsContext()

    return (
        <div
            className={'ListDealsList'}
            style={{
                maxHeight: 'auto',
            }}
        >
            <InfiniteScroll
                useWindow={false}
                pageStart={0}
                loadMore={() => shouldFetchNextDeals() && fetchNextDeals()}
                hasMore={listDealsState.has_more}
                className={'ListDealsList_container'}
            >
                {
                    listDealsState?.results?.map((deal) => {
                        const isSelected = Boolean(selectedDealId === deal.id)

                        if (isTabletOrMobile) {
                            return <ListDealMobileCard
                                setSelectedDealId={setSelectedDealId}
                                deal={deal}
                                key={deal.id}
                                isSelected={isSelected}
                            />
                        }

                        return <ListDealCard
                            setSelectedDealId={setSelectedDealId}
                            deal={deal}
                            key={deal.id}
                            isSelected={isSelected}
                        />
                    })
                }
            </InfiniteScroll>
        </div>
    )
}