import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from '@mui/icons-material/Star';
import React from "react";
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import Tooltip from "@mui/material/Tooltip";

import { toast } from 'react-toastify'

export const LocationItem = ( {
                                  location,
                                  deleteLocation,
                                  isSelected,
                                  setSelectedLocation,
                                  isInitialLocation,
                                  updateLocation
}) => {

    const isDefaultLocation = location.is_default_location

    const updatePinnedLocation = (isPinned) => {
        const data = { is_pinned: isPinned }
        return updateLocation(location.id, data).then(() => {
            const addedOrRemoved = isPinned ? 'added' : 'removed'
            toast.success(`Successfully ${addedOrRemoved} pinned location.`)
        })
    }

    return (
        <div
            className={classnames('LocationItem', {
                selected: isSelected
            })}
        >
            <div>
                <Checkbox
                    className={'LocationItem_checkbox'}
                    onChange={() => setSelectedLocation(location)}
                    checked={isSelected}
                    disabled={isSelected}
                />
            </div>
            <div className={'LocationItem_address'}>
                <span>{location.city}, </span>
                <span>{location.county}, </span>
                <span>{location.state}</span>
            </div>
            <div>

                {
                    location.is_pinned ?
                        <Tooltip title={'Remove Pin'} arrow placement={'top'}>
                            <PushPinIcon
                                onClick={() => updatePinnedLocation(false)}
                                className={'LocationItem-icon'}
                            />
                        </Tooltip>
                        :
                        <Tooltip title={'Pin location to calculate page'} arrow placement={'top'}>
                            <PushPinOutlinedIcon
                                onClick={() => updatePinnedLocation(true)}
                                className={'LocationItem-icon'}
                            />
                            </Tooltip>
                }
            </div>
            <div>
                {
                    !isInitialLocation && !isDefaultLocation &&
                    <DeleteIcon
                        onClick={() => deleteLocation(location.id)}
                        className={classnames('LocationItem-icon delete', {
                            selected: isSelected
                        })}
                    />
                }
                {
                    isDefaultLocation &&
                    <StarIcon
                        className={classnames('LocationItem-icon star', {
                            selected: isSelected
                        })}
                    />
                }
            </div>
        </div>
    )
}