import React from "react";
import Skeleton from "@mui/material/Skeleton";

export const SkeletonLoader = ( { loading, variant, children } ) => {
    if (loading) {
        return (
            <Skeleton variant={variant || 'text'}>
                {children}
            </Skeleton>
        )
    } else {
        return <>{children}</>
    }
}