import React, {useCallback, useState} from 'react'
import classnames from "classnames";
import QuoteHelper from "../../utils/QuoteHelper";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import {useDealContext} from "../../contexts/DealContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import TableHead from "@mui/material/TableHead";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {NumericFormat} from "react-number-format";
import {DealPageTaxItem} from "./DealPageTaxItem";
import {DealPageMonthlyTaxItem} from "./DealPageMontlyTaxItem";
import FeeHelper from "../../utils/FeeHelper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";


export const DealPageTaxes = () => {
    const { setDealStateWithUpdate, updateDealResponse, dealState } = useDealContext()
    const [helper, setHelper] = useState(new QuoteHelper(dealState))
    const [open, setOpen] = useState(false);

    useDidMountEffect(function() {
        setHelper(new QuoteHelper(dealState))
    }, [dealState])

    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    if (!(dealState && dealState?.selected_quote)) return null;

    const isPurchase = dealState.is_purchase
    const isLoading = updateDealResponse?.isLoading

    const taxedFees = helper.taxedFees()
    const customDriveOffFee = helper.driveOffFeeTaxed()

    const tooltip = dealState.is_tax_exempt ? "Check to apply taxes" : "Uncheck for tax exempt"

    return (
        <Box className={classnames("dealPageFees", {
            mobile: isTabletOrMobile
        })}>
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                className={'header header-row'}
            >
                <div
                    className={'header-name'}
                >
                        Taxes
                    <Tooltip title={tooltip} arrow placement={'top'}>
                        <Checkbox
                                className={'payment-row_value'}
                                onChange={() => setDealStateWithUpdate({is_tax_exempt: !dealState.is_tax_exempt}, true)}
                                checked={!dealState.is_tax_exempt}
                                disabled={isLoading}
                            />
                    </Tooltip>
                </div>
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div className={'header-amount'}>
                        <NumericFormat
                            value={isPurchase ? helper.taxAmount() : helper.totalTaxes()}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </div>
                    <div>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                </Stack>
            </Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={'header-cell'}
                                align="left"
                                width={'40%'}
                            >
                                Name
                            </TableCell>
                            {
                                !isTabletOrMobile &&
                                <TableCell
                                    className={'header-cell'}
                                    align="right"
                                    width={'25%'}
                                >
                                    Taxable Amount
                                </TableCell>
                            }
                            <TableCell
                                className={'header-cell'}
                                align="right"
                                width={'30%'}
                            >
                                Capped
                            </TableCell>
                            <TableCell
                                className={'header-cell'}
                                align="right"
                                width={'10%'}
                            >
                                Tax
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <DealPageMonthlyTaxItem/>
                        {
                            taxedFees.map((taxedFee) => {
                                const feeHelper = new FeeHelper(taxedFee)
                                return (
                                    <DealPageTaxItem
                                        key={feeHelper.uniqueId()}
                                        taxItem={taxedFee}
                                    />
                                )
                            })
                        }
                        {
                            customDriveOffFee &&
                            <DealPageTaxItem
                                taxItem={customDriveOffFee}
                                amount={helper.capReductionCash()}
                            />
                        }
                    </TableBody>
                </Table>
            </Collapse>
        </Box>
    )
}