import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import {useParams} from "react-router";

export const PURCHASE_DEAL_LIGHT = '#1BA897'
export const PURCHASE_DEAL_DARK = '#007D6E'
export const LEASE_DEAL_DARK = '#2267CE'
export const LEASE_DEAL_LIGHT = '#2B8EEA'

export const DeskingContext = createContext(undefined)

export const DeskingContextProvider = ( { children, id, ...props } ) => {
    const params = useParams();

    const dealId = () => {
        return id || params.id
    }

    const [deskingState, setDeskingState] = useState(null)
    
    const [fetchDealResponse, fetchDeal] = useFetchHook(fetcher.getDeal)
    const [updateDealResponse, updateDeal] = useFetchHook(fetcher.updateDeal)
    const [fetchDeskingResponse, fetchDesking] = useFetchHook(fetcher.getDesking)

    const dealColorDark = () => {
        return deskingState?.is_purchase ? PURCHASE_DEAL_DARK : LEASE_DEAL_DARK
    }

    const dealColorLight = () => {
        return deskingState?.is_purchase ? PURCHASE_DEAL_LIGHT : LEASE_DEAL_LIGHT
    }

    const dealColorDarkAlt = () => {
        return deskingState?.is_purchase ? LEASE_DEAL_DARK : PURCHASE_DEAL_DARK
    }

    const dealColorLightAlt = () => {
        return deskingState?.is_purchase ? LEASE_DEAL_LIGHT : PURCHASE_DEAL_LIGHT
    }

    const setDeskingStateWithUpdate = (newState, shouldUpdate = false) => {
        setDeskingState({...deskingState, ...newState })
    }

    const updateDealFunc = () => {
        const updateObj = structuredClone(deskingState)
        delete updateObj._updateInBackend
        return updateDeal(deskingState.id, updateObj)
    }

    useEffect(function fetchDealUsingID(){
        if (dealId()) {
            fetchDesking(dealId())
        }
    }, [dealId()])

    useEffect(function setFetchedDeskingQuote(){
        if (fetchDeskingResponse?.data?.data) {
            setDeskingStateWithUpdate(fetchDeskingResponse?.data?.data)
        }
    }, [fetchDeskingResponse])

    const value = {
        deskingState,

        fetchDesking,
        fetchDeskingResponse,

        updateDeal,
        updateDealResponse,

        fetchDeal,
        fetchDealResponse,

        dealId,
        setDeskingStateWithUpdate,

        dealColorDark,
        dealColorLight,
        dealColorDarkAlt,
        dealColorLightAlt,

        ...props
    }

    return (
        <DeskingContext.Provider value={value}>
            { children }
        </DeskingContext.Provider>
    )
}

export class NoDeskingContextError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NoDealContextError';
    }
}

export const useDeskingContext = () => {
    const context = useContext(DeskingContext)

    if (context === undefined) {
        throw new NoDeskingContextError('useDeskingContext must be used within DeskingContext')
    }
    return context
}