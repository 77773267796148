import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Box from "@mui/material/Box";
import React from "react";
import Skeleton from "@mui/material/Skeleton";

const DeskingPageLoadingRow = () => {
    return (
        <tr>
            {
                [...Array(5)].map(
                    (item, i) =>  (
                        <td key={i}>
                            <Skeleton
                                variant="rectangular"
                                width={130}
                                height={100}
                                style={{ borderRadius: '4px '}}
                            />
                        </td>
                    )
                )
            }
        </tr>
    )
}

export const DeskingPageLoading = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    if (!isTabletOrMobile) {
        return (
            <Box
                style={{
                    width: '895px',
                    height: '676px',
                    padding: '41px 89px',
                    display: 'flex'
                }}
            >
                <table
                    style={{
                        tableLayout: 'fixed',
                        width: '100%',
                    }}
                >
                    <tbody>
                        {
                            [...Array(5)].map((item, i) => <DeskingPageLoadingRow key={i}/>)
                        }
                    </tbody>
                </table>
            </Box>
        )
    }

}