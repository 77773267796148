import React from 'react'
import Box from "@mui/material/Box";

import {useListDealsContext} from "../../contexts/ListDealsContext";
import {ListDealsPageHeader} from "./ListDealsPageHeader";

import {ListDealsList} from "./ListDealsList";

import './ListDealsPage.scss'
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useModalContext} from "../../contexts/ModalContext";

export const ListDealsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { listDealsState } = useListDealsContext()
    const { isDealModalOpen, setIsDealModalOpen } = useModalContext()

    if (!listDealsState) return null;

    return (
        <React.Fragment>
            <Box
                style={{
                    display: isTabletOrMobile ? '' : 'flex',
                    height: '100vh',
                    overflow: isTabletOrMobile ? 'auto' : 'hidden',
                    flexDirection: isTabletOrMobile ? 'column' : '',
                    background: "#EFEFEF",
                }}
            >
                <Box
                    style={{
                        display: isTabletOrMobile ? 'flex' : '',
                        padding: isTabletOrMobile ? '30px' : '60px 30px',
                        flexGrow: 2,
                        maxWidth: isTabletOrMobile ? '100%' : '',
                        flexDirection: isTabletOrMobile ? 'column' : ''
                    }}
                    id={"ListDealsPage"}
                >
                    <ListDealsPageHeader/>
                    <ListDealsList
                        listDealsState={listDealsState}
                        setSelectedDealId={setIsDealModalOpen}
                        selectedDealId={isDealModalOpen}
                    />
                </Box>
            </Box>
        </React.Fragment>
    )
}