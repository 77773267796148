import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import QuoteHelper from "../../utils/QuoteHelper";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import FeeHelper from "../../utils/FeeHelper";
import {DealPageFee} from "./DealPageFee";
import classnames from 'classnames'
import TableCell from "@mui/material/TableCell";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {NumericFormat} from "react-number-format";

export const DealPageFeeList = (props) => {
    const {
        name,
        fees: feesList,
        isLoading,
        setDealStateWithUpdate,
        dealState
    } = props
    const [fees, setFees] = useState(() => {
        return _.orderBy(feesList, ['Amount'], ['desc'])
    })

    useEffect(() => {
        setFees(feesList)
    }, [feesList])

    const isTabletOrMobile = useMediaQuery({query: mediaQueryForMobile})
    const [open, setOpen] = React.useState(false);

    const helper = new QuoteHelper(dealState)

    return (
        <React.Fragment>
            <TableRow className={classnames('feesCategory', {open: open})}>
                <TableCell
                    onClick={() => setOpen(!open)}
                    width={'60%'}
                    className={classnames('DealPageFeeList-headers', {
                        open: open
                    })}
                >
                    <div
                        className={'header-name'}
                    >
                        {name}
                    </div>
                </TableCell>
                <TableCell
                    onClick={() => setOpen(!open)}
                    align="right"
                    width={'20%'}
                    className={classnames('DealPageFeeList-headers', {
                        open: open
                    })}
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </TableCell>
                <TableCell
                    onClick={() => setOpen(!open)}
                    aria-label="expand row"
                    align="right"
                    width={'20%'}
                    className={classnames('DealPageFeeList-headers', {
                        open: open
                    })}
                >
                    <NumericFormat
                        value={helper.sumFees(fees)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                    />
                </TableCell>
            </TableRow>
            <TableCell colSpan={4} style={{ padding: 0, border: 'none'}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="simple table">
                        <TableBody>
                            {
                                fees.map((fee) => {
                                        const feeHelper = new FeeHelper(fee)
                                        return (
                                            <DealPageFee
                                                isTabletOrMobile={isTabletOrMobile}
                                                key={feeHelper.uniqueId()}
                                                dealState={dealState}
                                                fee={fee}
                                                isLoading={isLoading}
                                                setDealStateWithUpdate={setDealStateWithUpdate}
                                            />
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </React.Fragment>
    )
}