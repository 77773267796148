import React from 'react'
import Box from "@mui/material/Box";
import {useUserContext} from "../../contexts/UserContext";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import KeyIcon from '@mui/icons-material/Key';

import { useForm } from "react-hook-form"

import {toast} from "react-toastify";
import {useFormResponse} from "../../hooks/useFormResponse";
import {PasswordInput} from "../../components/PasswordInput";
import CircularProgress from "@mui/joy/CircularProgress";


export const ChangePassword = () => {
    const { changePassword, changePasswordResponse, updateProfileResponse } = useUserContext()

    const {
        register,
        handleSubmit,
        setError,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        clearErrors()
        changePassword(data)
            .then((data) => {
                if (data?.response?.status === 400) {
                    toast.error("Please correct the errors and try again.")
                } else if (data.status === 200) {
                    toast.success("Successfully changed password.")
                }
            }).catch((data) => {
                toast.error("Something went wrong, please try again.")
            })
    }

    useFormResponse(setError, changePasswordResponse, [changePasswordResponse, handleSubmit])

    const isSaving = updateProfileResponse?.loading

    const isDisabled = () => {
        if (!watch('new_password1') || !watch('new_password2')) return false;

        return watch('new_password1') !== watch('new_password2');
    }

    const renderErrors = (field) => {
        return (
            errors[field]?.message?.map((message) =>
                <span
                    className={'form-errors'}
                    key={message}
                >
                    {message}
                </span>
            )
        )
    }

    return (
        <Box className={'ChangePassword'}>
            <label>
                <KeyIcon className={'password-icon'}/>
                Change Password
            </label>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PasswordInput
                    {...register('old_password')}
                    placeholder={'Old Password'}
                    className="ChangePassword__old-password"
                    type="password"
                />
                {renderErrors('old_password')}

                <PasswordInput
                    {...register('new_password1')}
                    placeholder={'New Password'}
                    className="ChangePassword__new-password1"
                    type="password"
                />
                {renderErrors('new_password1')}
                <PasswordInput
                    {...register('new_password2')}
                    placeholder={'New Password Again'}
                    className="ChangePassword__new-password2"
                    type="password"
                />
                {renderErrors('new_password2')}

                <Button
                    className={classnames('ChangePassword_button', {
                        disabled: isDisabled()
                    })}
                    type={'submit'}
                    variant="contained"
                    disabled={isDisabled()}
                >
                    {
                        isSaving ? <CircularProgress variant="soft" size={'sm'}/> : 'Change Password'
                    }
                </Button>
            </form>
        </Box>
    )
}