import React from 'react'
import {ErrorBoundary} from "react-error-boundary";
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import Button from "@mui/material/Button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    padding: '100px 120px'
};

const FallbackComponent = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    const navigate = useNavigate()

    const reset = () => {
        resetErrorBoundary()
        navigate('/calculate')
    }

    const isProduction = process.env.NODE_ENV === 'production'

    return (
        <Box style={style}>
            <Typography
                variant={'h2'}
            >
                ITS ELI'S FAULT THIS BROKE
            </Typography>
            <pre style={{ color: "red" }}>
                {
                    !isProduction &&
                    error.message
                }
                <div>
                    Please contact an admin and they will fix the issue.
                    <Button
                        onClick={reset}
                    >
                        Home
                    </Button>
                </div>
            </pre>
        </Box>
    );
}

export const ErrorBoundaryLogger = ({ children }) => {
    const [logErrorResponse, logError] = useFetchHook(fetcher.logError)

    const logErrorToService = (error, info) => {
        logError({ message: String(error), info })
    };

    return (
        <ErrorBoundary
            FallbackComponent={FallbackComponent}
            onError={logErrorToService}
        >
            {children}
        </ErrorBoundary>
    )
}