import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import {useParams} from "react-router";
import {searchPricings} from "../utils/utils";
import {useDebounce} from "@uidotdev/usehooks";

export const PricingContext = createContext(undefined)

export const PricingContextProvider = ( { children } ) => {
    const params = useParams();
    const [fetchPricingsResponse, fetchPricings] = useFetchHook(fetcher.getPricingsForMake)
    const [createPricingResponse, createPricing] = useFetchHook(fetcher.createPricing)
    const [deletePricingResponse, deletePricing] = useFetchHook(fetcher.deletePricing)
    const [updatePricingResponse, updatePricing] = useFetchHook(fetcher.updatePricing)
    const [createDealResponse, createDeal] = useFetchHook(fetcher.createDeal)

    const [pricingData, setPricingData] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const debouncedSearch = useDebounce(searchValue, 300)

    useEffect(() => {
        setPricingData([])
        fetchPricings(params.make)
    }, [params.make])

    useEffect(() => {
        if (fetchPricingsResponse?.data?.data && !fetchPricingsResponse?.loading) {
            setPricingData(fetchPricingsResponse.data.data || [])
        }
    }, [fetchPricingsResponse])

    useEffect(() => {
        return () => {
            setPricingData([])
        }
    }, [])

    const refreshData = () => {
        fetchPricings(params.make)
    }

    const value = {
        fetchPricings,
        fetchPricingsResponse,

        make: params.make,
        pricingData: searchPricings(pricingData, debouncedSearch),
        refreshData,

        createPricing,
        createPricingResponse,

        updatePricing,
        updatePricingResponse,

        deletePricing,
        deletePricingResponse,

        createDeal,
        createDealResponse,

        searchValue,
        setSearchValue,
        searchPricings
    }

    return (
        <PricingContext.Provider value={value}>
            { children }
        </PricingContext.Provider>
    )
}

export const usePricingContext = () => {
    const context = useContext(PricingContext)

    if (context === undefined) {
        throw new Error('usePricingContext must be used within PricingContext')
    }
    return context
}