import React, {useEffect, useState} from 'react'
import {useUserContext} from "../contexts/UserContext";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import {useMediaQuery} from "react-responsive";
import EditLocationIcon from '@mui/icons-material/EditLocation';

import useDidMountEffect from "../hooks/useDidMountEffect";
import Stack from "@mui/material/Stack";
import classnames from "classnames";
import {LocationItem} from "./LocationItem";
import {CreateLocationInput} from "./CreateLocationInput";
import {mediaQueryForMobile} from "../pages/HomePage/HomePage";
import CircularProgress from "@mui/joy/CircularProgress";
import {toast} from "react-toastify";


export const LocationsTable = (props) => {
   const {
       initialLocation,
       selectLocation,
       onLocationCreate,
       isDealLocation,
       isSaving,
       saveButtonText
   } = props
    const [selectedLocation, setSelectedLocation] = useState(initialLocation)

    const {
        updateProfileResponse,
        getLocations,
        getLocationsResponse,
        createLocation,
        createLocationResponse,
        deleteLocation,
        deleteLocationResponse,
        updateLocation,
        updateLocationResponse
    } = useUserContext()
    const [locations, setLocations] = useState([])

    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    useEffect(() => {
        getLocations()
    }, [])

    useDidMountEffect(() => {
        getLocations()
    }, [deleteLocationResponse, createLocationResponse, updateProfileResponse, updateLocationResponse])

    useEffect(() => {
        if (getLocationsResponse?.data?.data) {
            setLocations(getLocationsResponse.data.data || [])
        }
    }, [getLocationsResponse])

    const onSaveLocation = (locationData) => {
        createLocation(locationData).then((data) => {
            onLocationCreate && onLocationCreate(data)
            toast.success("Successfully added location.")
        })
    }

    return (
        <Box className={'LocationsTable'}>
            <div className={'LocationsTable_header'}>
                <EditLocationIcon/>
                <div>Add a New Location</div>
            </div>
            <Stack
                direction={isTabletOrMobile ? 'column': 'row'}
                justifyContent="space-between"
                alignItems="start"
                style={{minWidth: isTabletOrMobile ?  '' : '770px'}}
            >
                <CreateLocationInput
                    isSaving={createLocationResponse?.loading}
                    onSave={onSaveLocation}
                />
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div>
                        <label>{ isDealLocation ? 'Deal ' : 'Default '} Location</label>
                        {
                            initialLocation &&
                            <LocationItem
                                key={initialLocation.id}
                                location={initialLocation}
                                isSelected={initialLocation.id === selectedLocation.id}
                                setSelectedLocation={setSelectedLocation}
                                isInitialLocation={true}
                                updateLocation={updateLocation}
                            />
                        }
                    </div>
                    <Stack
                        direction="column"
                        sx={{
                            maxHeight: '800px'
                        }}
                    >
                        <label>Saved Locations</label>
                        <div
                            style={{
                                overflow: 'scroll',
                                height: '300px'
                            }}
                        >
                            {
                                locations.map((location) =>
                                    <LocationItem
                                        key={location.id}
                                        location={location}
                                        isSelected={location.id === selectedLocation.id}
                                        setSelectedLocation={setSelectedLocation}
                                        deleteLocation={deleteLocation}
                                        isInitialLocation={false}
                                        updateLocation={updateLocation}
                                    />
                                )
                            }
                        </div>
                        <Button
                            className={classnames('CreateLocationInput_button', {
                                disabled: false
                            })}
                            onClick={() => selectLocation(selectedLocation)}
                            disabled={initialLocation.id === selectedLocation.id}
                        >
                            {
                                isSaving ? <CircularProgress variant="soft" size={'sm'}/> : saveButtonText
                            }
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
