class TokenService {
    getLocalRefreshToken() {
        return localStorage.getItem("refreshToken");
    }

    getLocalAccessToken() {
        return localStorage.getItem("accessToken");
    }

    setLocalAccessToken(token) {
        return localStorage.setItem("accessToken", token);
    }

    setLocalRefreshToken(token) {
        return localStorage.setItem("refreshToken", token);
    }

    removeTokens() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
    }
}

const service = new TokenService()
export default service;