import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {baseStyles} from "../styles";

export const LocationInfoPDF = ( { deal } ) => {

    const location = deal.location
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
            }}
        >
            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >
                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    State:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { location.state }
                </Text>
            </View>
            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >

                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    County:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { location.county }
                </Text>
            </View>
            <View
                style={{...baseStyles.vehicleInfoContainer}}
            >
                <Text
                    style={{...baseStyles.vehicleInfoLabel}}
                >
                    City:
                </Text>
                <Text
                    style={{...baseStyles.vehicleInfo}}
                >
                    { location.city }
                </Text>
            </View>

        </View>
    )
}