import React from "react";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Divider from "@mui/material/Divider";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {useUserContext} from "../../contexts/UserContext";
import classnames from "classnames";

import {useUIContext} from "../../contexts/UIContext";
import {AppNavLinks} from "./AppNavlinks";
import Typography from "@mui/material/Typography";

import { ReactComponent as LogoSVG } from '../../assets/svgs/logo.svg'
import Stack from "@mui/material/Stack";

export const AppSidebarNav = () => {
    const { profileState } = useUserContext()
    const { uiState, toggleSideNavDrawer } = useUIContext()
    const {sideDrawerOpen, sideNavDrawerWidth, sideDrawerPinned} = uiState

    return (
        <div
            style={{ width: `${sideNavDrawerWidth}px` }}
            className={classnames('AppSidebarNav__container', {
                hidden: !sideDrawerOpen,
            })}
            onMouseEnter={() => toggleSideNavDrawer(true)}
            onMouseLeave={() => toggleSideNavDrawer(false)}
        >
            <div className={'AppSidebarNav-info'}>
                <div
                    className={classnames('arrow-container', {
                        pinned: sideDrawerPinned
                    })}
                    onClick={toggleSideNavDrawer}
                >
                    {/*{ sideDrawerOpen ? <KeyboardArrowLeftIcon/> : <KeyboardArrowRightIcon/>}*/}
                </div>
                <br></br>
                <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="h5"
                    className={'hide'}
                >
                    <Stack
                        flexDirection={'column'}
                        justifyContent="center"
                        alignItems={'center'}
                    >
                        <LogoSVG style={{ width: '150px'}}/>
                        {
                            profileState.account_name
                        }
                    </Stack>

                </Typography>
            </div>
            <Divider className={'divider'} />
            <AppNavLinks />
        </div>
    )
}