import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import LockIcon from '@mui/icons-material/Lock';
import Stack from "@mui/material/Stack";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    padding: '100px'
};

export const NotFoundPage = () => {
    return (
        <Box style={style}>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className={'input-group'}
            >
                <Typography
                    variant={'h4'}
                >
                    <div>This page doesn't exist.</div>
                </Typography>
                <div>
                    <dotlottie-player
                        style={{
                            width: "600px",
                            height: "600px"
                        }}
                        src="https://lottie.host/e664d552-5a8c-4b1f-a807-dffb087002b0/9k2wDKMvPW.json" background="transparent" speed="1" loop autoplay>

                    </dotlottie-player>
                </div>
            </Stack>
        </Box>
    );
}