import React, {forwardRef, useState} from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const iconStyle = {
    position: 'absolute',
    right: '10px',
    top: '33%',
    color: '#263238',
    cursor: 'pointer',
}

export const PasswordInput = forwardRef(function(props, ref) {
    const [isHidden, setIsHidden] = useState(true)

    return (
        <div style={{ position: 'relative'}}>
            <input
                ref={ref}
                {...props}
                type={isHidden ? 'password' : 'text'}
            />
            {
                isHidden ?
                    <VisibilityIcon style={iconStyle} onClick={() => setIsHidden(false)}/> :
                    <VisibilityOffIcon style={iconStyle} onClick={() => setIsHidden(true)}/>
            }
        </div>
    )
})