import React from 'react'
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

import {useListDealsContext} from "../../contexts/ListDealsContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";

export const ListDealsPageHeader = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { searchValue, setSearchValue } = useListDealsContext()

    return (
        <div
            className={'ListDealsPage_header'}
            style={{
                flexDirection: isTabletOrMobile ? 'column' : 'row'
            }}
        >
            <div
                className={'ListDealsPage_header-name'}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        color: '#666'
                    }}
                    component="div"
                >
                    Deals
                </Typography>
            </div>

            <div className={'ListDealsPage_header-search-container'}>
                <input
                    className=""
                    type="text"
                    value={searchValue}
                    placeholder={'Keyword search a car'}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <SearchIcon/>
            </div>

        </div>
    )
}