import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import QuoteHelper from "../../utils/QuoteHelper";
import classnames from "classnames";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useListDealsContext} from "../../contexts/ListDealsContext";
import Popover from "@mui/material/Popover";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";

export const ListDealCard = ( { deal, setSelectedDealId, isSelected } ) => {
    const { deleteDeal, removeDeletedDealFromList } = useListDealsContext()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDeleting, setIsDeleting] = useState(false)

    const car_detail = deal.car_detail

    const isLoading = false // updateDealResponse?.loading && dealId() === deal.id

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (id) => {
        return deleteDeal(id).then(() => removeDeletedDealFromList(deal.id))
    }

    const isPurchase = deal.is_purchase
    const helper = new QuoteHelper(deal, deal.selected_quote)

    return (
        <div
            onClick={() => setSelectedDealId(deal.id)}
            className={classnames('ListDealCard', {
                selected: isSelected,
                isPurchase: isPurchase
            })}
        >
            <MoreHorizIcon
                className={classnames(
                    'action-icon', {
                        loader: isDeleting
                    }
                )}
                onClick={handleClick}
            />
            <Popover
                // id={id}
                onClick={(e) => e.stopPropagation()}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <Typography
                    sx={{ p: 2, fontWeight: 500 }}
                    className={'ListDealCard-menu-item'}
                    onClick={() => handleDelete(deal.id)}
                >
                    Delete
                    <DeleteSharpIcon
                        className={classnames(
                            'action-icon', {
                                loader: isDeleting
                            }
                        )}
                    />
                </Typography>
            </Popover>
            <div
                className={'left'}
            >
                <div
                    className={'car-detail'}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}
                    >
                        {car_detail.year} {car_detail.make} {car_detail.model}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                        }}
                    >
                        {buildTrimAndStyleDescriptionName(car_detail.trim, car_detail.style_description)}
                    </Typography>
                </div>


                <div className={'ListDealCard-lender'}>
                    {helper.lender()}
                </div>

                <label>Description</label>
                <div className={'ListDealCard-description'}>
                    {deal.description}
                </div>
            </div>
            <div
                className={classnames('right', {
                    // isPurchase: isPurchase
                })}
            >
                <div
                    className={'pay-detail'}
                >
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            borderBottom: '1px solid white'
                        }}
                    >
                        { isPurchase ? 'PURCHASE' : 'LEASE'}
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}
                    >
                        ${isPurchase ? helper.cashDueOnDelivery() : helper.amountDueOnDelivery()}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        Due at Signing
                    </Typography>

                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}
                    >
                        ${isPurchase ? helper.monthlyPayment() : helper.advancePayment()}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        Per Month
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}
                    >
                        {deal.term}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        Months
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}
                    >
                        {deal.mileage}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        Miles
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                        }}
                    >
                        ${deal.profit}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        Profit
                    </Typography>
                </div>
            </div>
        </div>
    )
}