import React from 'react'
import {PricingListTable} from "./PricingListTable";
import {usePricingContext} from "../../contexts/PricingContext";

import PricingListBrands from "./PricingListBrands";
import Box from "@mui/material/Box";
import {PricingListTableSearchBarHeader} from "./PricingListSearchBarHeader";
import {useUserContext} from "../../contexts/UserContext";
import {useUIContext} from "../../contexts/UIContext";
import {PricingListPageMobile} from "./PricingListPageMobile/PricingListPageMobile";
import {useMediaQuery} from "react-responsive";

export const brandsWidth = 150
export const headerHeight = 160

export const PricingListPage = () =>  {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const { make, pricingData, fetchPricingsResponse } = usePricingContext()
    const { uiState } = useUIContext()
    const { profileState } = useUserContext()

    const {sideNavDrawerWidth} = uiState

    const page = () => {
        if (isTabletOrMobile) {
            return <PricingListPageMobile make={make}/>
        }

        return (
            <React.Fragment>
                {
                    profileState &&
                    <Box
                        style={{
                            width: `${brandsWidth}px`,
                        }}
                    >
                        <PricingListBrands
                            makesList={profileState.makes_list}
                        />
                    </Box>
                }
                <Box
                    style={{
                        flexGrow: 1,
                        bgcolor: 'background.default',
                        width: `calc(100% - ${brandsWidth}px)`,
                        padding: '0 15px',
                        opacity: fetchPricingsResponse?.loading? .5 : 1
                    }}
                >
                    <Box
                        style={{
                            width: `calc(100% - ${brandsWidth + sideNavDrawerWidth}px)`,
                            zIndex: 11,
                            position: 'fixed',
                            padding: '0 72px 0 0', // this padding is to account for the 0 36px above
                        }}
                    >
                        <PricingListTableSearchBarHeader
                            make={make}
                            headerHeight={headerHeight - 40}
                        />
                    </Box>
                    <Box>
                        <PricingListTable
                            pricingData={pricingData}
                            headerHeight={headerHeight}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        )
    }

    return (
        <div id="PricingListPage">
            { page() }
        </div>
    );
}
