import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import _ from "lodash";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {useDrag, useDrop} from "react-dnd";
import Tooltip from "@mui/material/Tooltip";

export const PricingListBrandsMakeCell = (props) => {
    const {
        make,
        removeMake,
        addMake,
        isIncluded,
        index,
        updateMakePosition,
        hasDeal,
        isEditing
    } = props
    const [{ draggedMake }, drag] = useDrag(
        () => ({
            type: 'PricingListBrandsMakeCell',
            item: { make },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
                draggedMake: monitor.getItem()
            })
        }),
        [make, updateMakePosition]
    )

    const onDrop = (item, monitor, collectedProps, drop) => {
        const { targetIndex } = collectedProps
        updateMakePosition(item, targetIndex)
    }

    const [collectedProps, drop] = useDrop(() => ({
        accept: 'PricingListBrandsMakeCell',
        drop: (item, monitor) => onDrop(item, monitor, collectedProps, drop),
        collect: (monitor) => ({
            targetIndex: index,
            isOver: monitor.isOver(),
            draggedMake: monitor.getItem()
        })
    }), [make, updateMakePosition])

    if (isEditing) {
        return (
            <div
                className={classnames('navlink-container', {
                    isHovered: collectedProps.isOver
                })}
                id={index}
                ref={drag}
            >
                <Tooltip title={isIncluded ? "Remove" : "Add"} arrow placement={'top'}>
                    {
                        isIncluded ?
                            <RemoveCircleOutlineIcon
                                className={'bookmarkIcon'}
                                onClick={removeMake}/> :
                            <AddCircleIcon
                                className={'bookmarkIcon'}
                                onClick={addMake}
                            />
                    }
                </Tooltip>
                <div
                    className={'brand-navlink__edit'}
                    ref={drop}
                    id={index}
                >
                    <span>
                        {_.capitalize(make)}
                    </span>
                    <span>
                        <Tooltip title={"Drag to reorder"} arrow placement={'top'}>
                            <DragHandleIcon
                                className={'draghandle'}
                            />
                        </Tooltip>
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className={'navlink-container'}>
            {/*<RequestQuoteOutlinedIcon*/}
            {/*    className={classnames('deal-icon', {*/}
            {/*        unhide: hasDeal */}
            {/*    })}*/}
            {/*/>*/}
            <NavLink
                key={make}
                // ref={dragRef}
                to={`/pricing/${make}`}
                className={({isActive, isPending, isTransitioning}) =>
                    [
                        "brand-navlink",
                        isPending ? "pending" : "",
                        isActive ? "active" : "",
                        isTransitioning ? "transitioning" : "",
                        hasDeal ? 'hasDeal' : ''
                    ].join(" ")
                }
            >
                <span>
                    {_.capitalize(make)}
                </span>

                <span className={'pricingBrandsCellChevronRightIcon'}>
                    <ChevronRightIcon/>
                </span>
            </NavLink>
        </div>
    )
}