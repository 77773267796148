import React from 'react'
import {Document, Page, Text, View} from "@react-pdf/renderer";
import {PaymentInformationPDF} from "../../components/PaymentInformationPDF";

import { baseStyles} from "../../styles";
import {AmountDueAtSigningPDF} from "../../components/AmountDueAtSigningPDF";
import {TaxInformationPDF} from "../../components/TaxInformationPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {ResidualInformationPDF} from "../../components/ResidualInformationPDF";
import {MileageInformationPDF} from "../../components/MileageInformationPDF";
import {HeaderPDF} from "../../components/HeaderPDF";


export const CustomerDisclosurePDF = ( { deal, companyName } ) => {
    if (!deal || !deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>

                <HeaderPDF deal={deal} pdfName={'Customer Disclosure'} companyName={companyName}/>

                <VehicleInfoPDF deal={deal}/>

                <View style={{...baseStyles.tablesLayout}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <AmountDueAtSigningPDF deal={deal}/>
                        <PaymentInformationPDF deal={deal} />
                        <ResidualInformationPDF deal={deal} />
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <TaxInformationPDF deal={deal}/>
                        <MileageInformationPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}