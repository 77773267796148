import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {LEASE_DEAL_LIGHT} from "../../contexts/DealContext";
import {useModalContext} from "../../contexts/ModalContext";
import {useCalcContext} from "../../contexts/CalcContext";
import Radio from "@mui/material/Radio";
import {useUserContext} from "../../contexts/UserContext";
import LocationHelper from "../../utils/LocationHelper";
import PushPinIcon from '@mui/icons-material/PushPin';


export const CalcPageSelectLocation = () => {
    const { profileState } = useUserContext()
    const { setIsLocationModalOpen } = useModalContext()

    const {
        calcState,
        setCalcState,
    } = useCalcContext()

    const handleLocationChange = (newLocation) => {
        setCalcState({...calcState, location: newLocation, location_id: newLocation.id})
    }

    const defaultLocation = profileState.default_location
    const selectedLocation = calcState.location

    return (
        <React.Fragment>
            <Typography
                gutterBottom
                variant="h6"
                component="div"
                className={'header location-info'}
                sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                }}
            >
                <span>02 Choose Location</span>
            </Typography>
            <Divider className={'divider'}/>

            <Typography
                sx={{
                    fontSize: '16px',
                    marginTop: '10px',
                    color: '#666'
                }}
            >
                <Radio
                    checked={defaultLocation.id === selectedLocation.id}
                    onChange={() => handleLocationChange(defaultLocation)}
                    name={new LocationHelper(defaultLocation).name()}
                    value={defaultLocation}

                />
                <span>{defaultLocation.city}, </span>
                <span>{defaultLocation.county}, </span>
                <span>{defaultLocation.state}</span>

            </Typography>
            <Divider className={'divider'}/>
            <div className={'Pinned-locations-container'}>
                {
                    profileState?.pinned_locations?.map(
                        (location) => {
                            const locationHelper = new LocationHelper(location)
                            return (
                                <Typography
                                    key={location.id}
                                    sx={{
                                        fontSize: '16px',
                                        marginTop: '10px',
                                        color: '#666'
                                    }}
                                >
                                    <Radio
                                        checked={location.id === selectedLocation.id}
                                        onChange={() => handleLocationChange(location)}
                                        value={location}
                                        name={locationHelper.name()}
                                    />
                                    <span>{location.city}, </span>
                                    <span>{location.county}, </span>
                                    <span>{location.state}</span>
                                </Typography>
                                )
                        }
                    )
                }
            </div>


            <Button
                className={'dealPage-button'}
                style={{ background: LEASE_DEAL_LIGHT }}
                onClick={setIsLocationModalOpen}
            >
                Edit Location
            </Button>
        </React.Fragment>
    )
}