import React, {useState} from "react";
import {useUserContext} from "../../contexts/UserContext";
import './PricingListPage.scss'
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import {PricingListBrandsMakeCell} from "./PricingListBrandsMakeCell";
import classnames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import {brandsWidth} from "./PricingListPage";

const MAKES_LIST = ['Acura', 'Alfa Romeo', 'Aston Martin', 'Audi', 'BrightDrop', 'Bentley', 'BMW', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Dodge', 'FIAT', 'Ford', 'Ferrari', 'Fisker', 'GMC', 'Genesis', 'Honda', 'Hyundai', 'INEOS', 'INFINITI', 'Jaguar', 'Jeep', 'Kia', 'Lexus', 'Lamborghini', 'Lincoln', 'Lotus', 'Land Rover', 'Lucid', 'Mazda', 'Mercedes-Benz', 'Mitsubishi', 'McLaren', 'MINI', 'Maserati', 'Nissan', 'Porsche', 'Polestar', 'Rivian', 'Ram', 'Subaru', 'Toyota', 'Tesla', 'INEOS', 'VinFast', 'Volvo', 'Volkswagen']

const PricingListBrands = ( { makesList }) => {
    const { profileState, setProfileStateWithUpdate } = useUserContext()
    const [searchState, setSearchState] = useState([])
    const [isEditing, setIsEditing] = useState(false)

    const removeMake = (make) => {
        const newList = makesList.filter((m) => m !== make)
        setProfileStateWithUpdate({makes_list: newList})
    }

    const addMake = (make) => {
        const newList = [...makesList, make]
        setProfileStateWithUpdate({makes_list: newList})
    }

    const updateMakePosition = (item, targetIndex) => {
        const { make } = item

        const newList = makesList.filter((m) => m !== make)
        newList.splice(targetIndex, 0, make)
        setProfileStateWithUpdate({makes_list: newList})
    }

    const buildMakesList = () => {
        if (searchState.length && searchState.trim()) {
            return MAKES_LIST.filter((m) => m.toLowerCase().startsWith(searchState.toLowerCase()))
        } else {
            return profileState.makes_list
        }
    }

    const isIncluded = (make) => {
        return profileState?.makes_list?.includes(make)
    }

    return (
        <div
            className={'pricingBrandsContainer'}
            style={{
                width: `${brandsWidth}px`,
            }}
        >
            <Tooltip title={"Edit List"} arrow placement={'top'}>
                <EditIcon
                    onClick={() => setIsEditing(!isEditing)}
                    className={classnames('editIcon', {
                        isEditing: isEditing
                    })}
                />
            </Tooltip>
            <div className={'pricingBrandsSearchContainer'}>
                <span><SearchIcon/></span>
                <input
                    style={{ maxWidth: '140px'}}
                    id={"PricingListBrandsMakeSearch"}
                    className="form-control"
                    type="text"
                    placeholder={'Brands'}
                    value={searchState}
                    onChange={(e) => setSearchState(e.target.value)}
                />
            </div>
            <div className={'pricingBrands'}>
                {
                    buildMakesList()?.map(
                        (make, index) => {
                                return (
                                    <PricingListBrandsMakeCell
                                        key={index}
                                        isEditing={isEditing}
                                        index={index}
                                        make={make}
                                        removeMake={() => removeMake(make)}
                                        addMake={() => addMake(make)}
                                        updateMakePosition={(item, targetIndex) => updateMakePosition(item, targetIndex)}
                                        isIncluded={isIncluded(make)}
                                        hasDeal={profileState?.makes_with_pricings[make.toLowerCase()]}
                                    />
                                )
                            }
                        )
                }
            </div>
        </div>
    )
}

export default PricingListBrands