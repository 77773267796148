import { useEffect } from 'react';

const useEnterEffect = (func, deps, stopImmediatePropagation = false) => {
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                event.stopPropagation();

                stopImmediatePropagation && event.stopImmediatePropagation()

                func(event)
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, deps);
}

export default useEnterEffect;