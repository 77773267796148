import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import LockIcon from '@mui/icons-material/Lock';
import Stack from "@mui/material/Stack";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    padding: '100px'
};

export const ForbiddenPage = () => {

    return (
        <Box style={style}>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className={'input-group'}
            >
                <LockIcon
                    style={{
                        height: '100px',
                        width: '100px'
                    }}
                />
                <Typography
                    variant={'h4'}
                >
                    <div>You don't have permission to view this page.</div>
                </Typography>
                <pre style={{ color: "red" }}>
                <div>
                    <div>
                        <span>{ window?.history?.state?.message } </span>
                        <span>Please contact an admin to fix the issue.</span>
                    </div>

                </div>
            </pre>
            </Stack>
        </Box>
    );
}