import React from 'react'
import {Document, Page, View} from "@react-pdf/renderer";

import { baseStyles} from "../../styles";
import {FeeBreakdownPDF} from "../../components/FeeBreakdownPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {PurchaseCustomerPaymentInformationPDF} from "./PurchaseCustomerPaymentInformationPDF";
import {PurchaseCustomerPriceBreakdownPDF} from "./PurchaseCustomerPriceBreakdownPDF";
import {HeaderPDF} from "../../components/HeaderPDF";

export const PurchaseCustomerDisclosurePDF = ( { deal, companyName } ) => {
    if (!deal || ! deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>
                <HeaderPDF deal={deal} pdfName={''} companyName={companyName}/>

                <VehicleInfoPDF deal={deal}/>

                <View style={{...baseStyles.tablesLayout}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <PurchaseCustomerPaymentInformationPDF deal={deal}/>
                        <FeeBreakdownPDF deal={deal}/>
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <PurchaseCustomerPriceBreakdownPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}