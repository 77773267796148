import React, { useState} from 'react'
import _ from 'lodash'
import {PricingListTableHeaders} from "./PricingListTableHeaders";
import {PricingListRow} from "./PricingListRow";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandCircleDownSharpIcon from '@mui/icons-material/ExpandCircleDownSharp';

import './PricingListPage.scss'
import {usePricingContext} from "../../contexts/PricingContext";

import {buildStyleDescriptionName} from "../../utils/utils";
import {headerHeight} from "./PricingListPage";

const TOTAL_COLUMNS = 10

const TableCells = ( { times, style, className } ) => {
    return _.times(times, (n) =>
        <td
            style={style}
            key={n}
            className={className}
        />
    )
}

const PricingListPricing = ( { pricing, trim, style_description} ) => {
    return (
        <tr>
            <TableCells times={TOTAL_COLUMNS - 8}/>
            <PricingListRow
                pricing={pricing}
                trim={trim}
                style_description={style_description}
            />
        </tr>
    )
}

const PricingListTrim = ( { year, trim, collapsed, style_description } ) => {
    const renderPricings = () => _.map(style_description.pricings, (pricing) => {
        return (
            <PricingListPricing
                key={pricing.id}
                pricing={pricing}
                style_description={style_description}
                trim={trim}
                year={year}
            />
        )
    })

    if (collapsed) return null;

    return (
        <React.Fragment>
            <tr>
                <TableCells times={TOTAL_COLUMNS - 8}/>
                <td className={'trim-sd-name'}>
                    <div className={'trim-name'}>{trim.name}</div>
                    <div className={'sd-name'}>
                        {buildStyleDescriptionName(trim.name, style_description.name)}
                    </div>
                </td>
                <PricingListRow style_description={style_description}/>
            </tr>
            { renderPricings() }
        </React.Fragment>
    )
}

const PricingListModel = ( { year, model, collapsed, hasPricing } ) => {
    const { searchValue } = usePricingContext()
    const [modelCollpased, setModelCollapsed] = useState(!hasPricing && !searchValue)

    const renderTrims = () => {
        return (
            _.map(model.trims, (trim) => {
                return _.map(trim.style_descriptions, (sd) =>
                        <PricingListTrim
                            key={sd.id}
                            style_description={sd}
                            trim={trim}
                            collapsed={modelCollpased}
                            year={year}
                        />
                    )
                }
            )
        )
    }
    if (collapsed) return null;

    return (
        <React.Fragment>
            <tr>
                <TableCells times={TOTAL_COLUMNS - 9}/>
                    <td className={'PricingListTable-model_name'}>
                        <div className={'PricingListTable-model_name-button'} onClick={() => setModelCollapsed(!modelCollpased)}>
                            {
                                modelCollpased ?
                                    <ExpandMoreIcon
                                        className={'ExpandMoreIcon'}
                                        onClick={() => setModelCollapsed(!modelCollpased)}
                                    /> :
                                    <ExpandCircleDownSharpIcon
                                        className={'ExpandCircleUpSharpIcon'}
                                        onClick={() => setModelCollapsed(!modelCollpased)}
                                    />
                            }
                            {model.name}
                        </div>
                    </td>
                <TableCells times={TOTAL_COLUMNS - 2}/>
            </tr>
            { renderTrims() }
        </React.Fragment>
    )
}

const PricingListYear = ( { year, models, hasPricing } ) => {
    const { searchValue } = usePricingContext()
    const [yearCollpased, setYearCollapsed] = useState(!hasPricing && !searchValue)

    const hasPricings = (model) => (model.pricings_count > 0)

    const renderModels = () => {
        return (
            _.map(models, (model) => {
                        const hasPricing = hasPricings(model)
                        return <PricingListModel
                            key={`${year}-${model.id}`}
                            year={year}
                            hasPricing={hasPricing}
                            model={model}
                            collapsed={yearCollpased}
                        />
                    }
                )
        )
    }

    return (
        <React.Fragment>
            <tr>
                <td
                    style={{top: `${headerHeight}px`}}
                    className={'sticky-year-td'}
                >
                    <div className={'PricingListTable-year_name-button'}>
                        {
                            yearCollpased ?
                                <ExpandMoreIcon
                                    className={'ExpandMoreIcon'}
                                    onClick={() => setYearCollapsed(!yearCollpased)}
                                /> :
                                <ExpandCircleDownSharpIcon
                                    onClick={() => setYearCollapsed(!yearCollpased)}
                                    className={'ExpandCircleUpSharpIcon'}
                                />
                        }
                        {year}
                    </div>
                </td>
                <TableCells
                    style={{top: `${headerHeight}px`}}
                    times={TOTAL_COLUMNS - 1}
                    className={'sticky-year-td'}
                />
            </tr>
            { renderModels() }
        </React.Fragment>
    )
}

export const PricingListTable = ( { pricingData, headerHeight } ) => {
    const { make } = usePricingContext()

    const hasPricings = (models) =>  {
        return models.some((model) => model.pricings_count > 0)
    }

    const renderYears = () => {
        return (
            _.map(pricingData, (models, year) => {
                const hasPricing = hasPricings(models)
                return (
                    <PricingListYear
                        key={`${year}-${make}`}
                        year={year}
                        models={models}
                        hasPricing={hasPricing}
                    />
                )
            })
        )
    }

    return (
        <React.Fragment>
            <table>
                <PricingListTableHeaders headerHeight={headerHeight} />
                <tbody>
                    {
                        renderYears()
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}